import { DateFormat } from '@range.io/basic-types'
import { mergeRight } from '@range.io/functional'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import Icon from '../components-reusable/Icon.js'
import {
    Avatar,
    Box,
    Button,
    DatePicker,
    Flex,
    FlexColumn,
    FlexRow,
    RowBox,
    SingleSelectUser,
    TextAreaExternallyChangeable,
} from '../components-reusable/index.js'
import PossiblySuspendedUserName from '../components-reusable/PossiblySuspendedUserName.js'
import { styled } from '../range-theme/index.js'
import CollaborationWindowBadge from './CollaborationWindowBadge.js'
import { StyledFeatureDataBar, StyledNameInput } from './CollaborationWindowCommon.js'
import StatusSelector from './StatusSelector.js'
import Tags from './Tags.js'
import { FollowButtonSimple } from './FollowButton.js'
import FollowersSelector from './FollowersSelector.js'

const StyledDescriptionInput = styled(TextAreaExternallyChangeable, {
    border: 'none',
    background: 'none',
    br: '0px',
    mb: 8,

    '&:hover': { border: 'none' },
    '&:active': { border: 'none' },
    '&:focus': { border: 'none' },
    '&::placeholder': { border: 'none', color: '$neutral05' },
})

// ---------------------------------------------------------------------------------------------------------------------
// Buttons
// ---------------------------------------------------------------------------------------------------------------------

/*
 * There's no assignee: show a button that says "Add Assignee"
 */
const AddAssigneeButton = ({ onClick }) => {
    const css = {
        w: 'calc(50% - 8px)',
        justifyContent: 'flex-start',
        fs: 14,
        fw: 500,
        color: '$neutral04',
        pl: '8px',
    }
    return (
        <Button variant="secondary" size="xl" css={css} onClick={onClick}>
            <Icon
                name="user"
                iconSize="16px"
                css={{ br: 1000, bg: '$neutral10', border: '1px solid $neutral07', p: 6, boxSizing: 'content-box' }}
            />
            Add assignee
        </Button>
    )
}

/*
 * Cancel the assignment
 */
const CancelIconButton = styled(Icon, {
    zIndex: 2,
    ml: 'auto',
    p: '8px',
    br: '1000px',
    c: '$neutral04',
    bg: '$neutral10',
    border: '1px solid $neutral07',
    transitionDuration: '0.4s',
    boxSizing: 'content-box',

    '&:hover': { border: '1px solid $primary04', bg: '$neutral10' },
})

/*
 * There's an assignee: show a button with their avatar and name
 * @sig CurrentAssigneeButton :: ({ assignee: Participant, onClick: Thunk, onUnassign: Thunk }) -> ReactElement
 *  Thunk = () -> void
 */
const CurrentAssigneeButton = ({ assignee, onClick, onUnassign }) => {
    const buttonCss = { w: 'calc(50% - 8px)', justifyContent: 'flex-start', pl: '8px', pr: '8px' }
    const fullNameCss = {
        fs: 14,
        fw: 500,
        color: '$neutral04',
        maxWidth: '127px',
    }
    return (
        <Button variant="secondary" size="xl" css={buttonCss} onClick={onClick}>
            <Box>
                <Avatar size="28" participantShape={assignee} />
            </Box>
            <FlexColumn css={{ alignItems: 'flex-start' }}>
                <Box css={{ fs: 12, fw: 400, color: '$neutral05' }}>Assigned to</Box>
                <Box css={fullNameCss}>
                    <PossiblySuspendedUserName participantShape={assignee} />
                </Box>
            </FlexColumn>
            <CancelIconButton
                onClick={e => {
                    e.stopPropagation() // prevent bubbling so the other onClick is not triggered
                    onUnassign(e)
                }}
                name="close"
                iconSize="8px"
            />
        </Button>
    )
}

/*
 * Depending on the state, we might need to show
 *
 *   no assignee selected: Add assignee
 *   assignee selected     Assigned to
 *   selecting assignee    Enter name
 *
 * @sig AssigneeButtonSelector :: ({ Participant, [ParticipantShape], Thunk, Boolean }) -> ReactElement
 *
 */
const AssigneeButtonSelector = ({ assignee, projectParticipantShapes, onAssigneeChanged, initialOpen = false }) => {
    // in the case where we're currently NOT showing the user selection dropdown, clicking on the button will
    // cause the assignee selector to appear
    const showAssignees = () => setShowAssigneeDropdown(true)

    // assign the user and hide the dropdown
    const _onAssigneeChanged = userId => {
        setShowAssigneeDropdown(false)
        if (!assignee || assignee.id !== userId) onAssigneeChanged(userId) // trigger onAssigneeChanged if assignee has actually changed
    }

    // unassign the user and hide the dropdown
    const onUnassign = () => {
        setShowAssigneeDropdown(false)
        onAssigneeChanged(null)
    }

    // there are three choices: we don't have an assignee at all, we have one, we're changing the assignee
    const assigneeButton = () => {
        const props1 = {
            projectParticipantShapes,
            selectedUser: assignee,
            withNoAssigneeOption: true,
            setSelectedUserId: _onAssigneeChanged,
        }
        const projectParticipantShape = assignee && projectParticipantShapes.find(p => p.id === assignee.id)
        const props2 = { assignee: projectParticipantShape, onClick: showAssignees, onUnassign }

        if (showAssigneeDropdown) return <SingleSelectUser {...props1} />
        if (assignee) return <CurrentAssigneeButton {...props2} />
        return <AddAssigneeButton onClick={showAssignees} />
    }

    const [showAssigneeDropdown, setShowAssigneeDropdown] = useState(initialOpen)

    return assigneeButton()
}

/*
 * There's no due date: show a button that says "Add due date"
 */
const AddDueDateButton = ({ isArchived, isCompleted, dueDate, onDueDateChanged, initialOpen }) => {
    const [isPickingDate, setIsPickingDate] = useState(initialOpen)
    const datePickerRef = useRef(null) // Ref for the date picker

    const hideDatePicker = () => setIsPickingDate(false)
    const toggleDatePicker = () => setIsPickingDate(!isPickingDate)

    const _onDueDateChanged = date => {
        onDueDateChanged(date)
        hideDatePicker()
    }

    const removeDueDate = e => {
        _onDueDateChanged(null)
        e.stopPropagation() // prevent the toggle on the parent button
    }

    // Handle outside click and escape key
    const handleOutsideClickAndEscape = event => {
        // Check for outside click
        if (event.type === 'mousedown' && datePickerRef.current && !datePickerRef.current.contains(event.target)) {
            hideDatePicker()
        }
        // Check for Escape key
        if (event.type === 'keydown' && event.key === 'Escape') {
            hideDatePicker()
            event.stopPropagation() // Stop the event from propagating further
        }
    }

    // Attach event listeners when the date picker is open
    useEffect(() => {
        if (isPickingDate) {
            document.addEventListener('mousedown', handleOutsideClickAndEscape)
            document.addEventListener('keydown', handleOutsideClickAndEscape)
        }

        // Clean up event listeners
        return () => {
            document.removeEventListener('mousedown', handleOutsideClickAndEscape)
            document.removeEventListener('keydown', handleOutsideClickAndEscape)
        }
    }, [isPickingDate])

    // Display the DatePicker on top of the button
    const renderDatePicker = () => (
        <Box ref={datePickerRef} css={{ position: 'absolute', top: '48px', right: '-8px', zIndex: 999 }}>
            <DatePicker onChange={_onDueDateChanged} value={dueDate} />
        </Box>
    )

    // Display the due date (or "Add due date") and, if present, a cancel button to remove it
    const renderDueDate = () => {
        const overdue = !isArchived && !isCompleted && dueDate < Date.now()
        const color = overdue ? '$red03' : '$neutral04'
        const background = overdue ? '$red01' : '$neutral09'
        const border = overdue ? '1px solid $red03' : '1px solid $neutral07'
        const borderHover = overdue ? '1px solid $red03' : '1px solid $primary03'
        const borderHoverIcon = overdue ? '1px solid $red03' : '1px solid $primary04'
        const backgroundHover = overdue ? '$red02' : '$primary02'
        const css = {
            w: 'calc(50% - 8px)',
            justifyContent: 'flex-start',
            fs: 14,
            fw: 500,
            pl: '8px',
            pr: '8px',
            color,
            border,
            background,

            '&:hover': {
                color: color + '!important',
                border: borderHover + '!important',
                background: backgroundHover + '!important',
            },
        }

        const calendarIconCss = {
            br: 1000,
            bg: '$neutral10',
            border,
            p: 6,
            boxSizing: 'content-box',
        }

        const closeButtonCss = {
            color: '$neutral04',
            border,

            '&:hover': {
                color: color + '!important',
                border: borderHoverIcon + '!important',
            },
        }

        const selectedDateText = dueDate ? DateFormat.primary(dueDate) : 'Add due date'

        return (
            <Button variant="secondary" size="xl" css={css} onClick={toggleDatePicker}>
                <Icon name="calendar" iconSize="16px" css={calendarIconCss} />
                <FlexColumn css={{ alignItems: 'flex-start' }}>
                    {dueDate && <Box css={{ color: '$neutral05', fs: 12, fw: 400 }}>Due Date</Box>}
                    <Box css={{ fs: 14, fw: 500 }}>{selectedDateText}</Box>
                </FlexColumn>
                {dueDate && (
                    <CancelIconButton css={closeButtonCss} name="close" onClick={removeDueDate} iconSize="8px" />
                )}
            </Button>
        )
    }

    return (
        <>
            {renderDueDate()}
            {isPickingDate && renderDatePicker()}
        </>
    )
}

const StyledArchivedBubble = styled(Flex, {
    flexDirection: 'row',
    alignItems: 'center',
    fontFamily: '$default',
    fontWeight: 500,
    fontSize: 14,
    padding: '7px 10px',
    borderRadius: 999,
    color: 'white',
    backgroundColor: '$archived',
    height: 32,
})

/*
 * Top row of Task: shows Badge + StatusSelector
 */
const TaskHeader = ({
    statusName,
    allStatusNames,
    onStatusChanged,
    isFollowing,
    followers,
    participantsForFollowersSelector,
    onFollowClick,
    onFollowerSelected,
    isArchived = false,
    isOverdue = false,
    hasNotesPending = false,
    css = {},
}) => {
    const isCompleted = statusName.isCompleted
    const props = { isCompleted, isOverdue, isTask: true, isArchived, color: statusName.color, hasNotesPending }

    css = mergeRight(
        { ai: 'center', gap: '10px', zIndex: 999, flexDirection: 'row', justifyContent: 'space-between' },
        css
    )

    return (
        <RowBox css={css}>
            <FlexRow
                style={{
                    gap: '10px',
                    flexDirection: 'row',
                }}
            >
                <CollaborationWindowBadge {...props} />
                {!isArchived && (
                    <StatusSelector
                        onStatusChanged={onStatusChanged}
                        initialStatusName={statusName}
                        allStatusNames={allStatusNames}
                    />
                )}
                {isArchived && <StyledArchivedBubble>Archived</StyledArchivedBubble>}
            </FlexRow>
            <FlexRow>
                <FollowButtonSimple isFollowing={isFollowing} onClick={() => onFollowClick(!isFollowing)} />
                <FollowersSelector
                    multiSelect
                    participants={participantsForFollowersSelector}
                    onFollowerSelected={onFollowerSelected}
                    followers={followers}
                />
            </FlexRow>
        </RowBox>
    )
}
TaskHeader.propTypes = {
    statusName: PropTypes.object.isRequired,
    allStatusNames: PropTypes.object.isRequired,
    onFollowClick: PropTypes.func.isRequired,
    onStatusChanged: PropTypes.func.isRequired,
    isFollowing: PropTypes.bool.isRequired,

    isOverdue: PropTypes.bool,
    isArchived: PropTypes.bool,
    hasNotesPending: PropTypes.bool,
}

/*
 * Bottom row of Task: shows Assignee and DueDate buttons
 */
const AssigneeAndDueDateButtons = ({
    assignee,
    projectParticipantShapes,
    isArchived,
    isCompleted,
    dueDate,
    onDueDateChanged,
    onAssigneeChanged,
    css = {},
    initialAssigneeOpen,
    initialDueDateOpen,
}) => {
    css = mergeRight({ gap: 16, position: 'relative' }, css)
    return (
        <FlexRow css={css}>
            <AssigneeButtonSelector
                assignee={assignee}
                projectParticipantShapes={projectParticipantShapes}
                onAssigneeChanged={onAssigneeChanged}
                initialOpen={initialAssigneeOpen}
            />
            <AddDueDateButton
                isArchived={isArchived}
                isCompleted={isCompleted}
                dueDate={dueDate}
                onDueDateChanged={onDueDateChanged}
                initialOpen={initialDueDateOpen}
            />
        </FlexRow>
    )
}
AssigneeAndDueDateButtons.propTypes = {
    assignee: PropTypes.object,
    projectParticipantShapes: PropTypes.arrayOf(PropTypes.object).isRequired,
    isArchived: PropTypes.bool,
    isCompleted: PropTypes.bool,
    dueDate: PropTypes.object,
    initialAssigneeOpen: PropTypes.bool, // for testing
    initialDueDateOpen: PropTypes.bool, // for testing

    onAssigneeChanged: PropTypes.func.isRequired,
    onDueDateChanged: PropTypes.func.isRequired,
}

/*
 * Entire editing area for a Task:
 *
 *   Badge + StatusSelector
 *   Name
 *   Description
 *   Assignee Button + DueDate Button
 */
const CollaborationWindowEditTask = ({
    collaborationIdentifierComponent,
    collaborationShape,
    projectParticipantShapes,
    name,
    description,
    isArchived = false,
    isCompleted = false,
    statusName,
    allStatusNames,
    assignee,
    tags = [],
    dueDate,
    inputRef,
    isFollowing,
    participantsForFollowersSelector,
    onFollowClick,
    onFollowerSelected,
    onAssigneeChanged,
    onStatusChanged,
    onNameChanged,
    onDescriptionChanged,
    onTagsChanged,
    onDueDateChanged,
    css = {},
}) => {
    const pendingNotes = collaborationShape.comments.filter(c => c.isNote && !c.completedBy)
    const hasNotesPending = pendingNotes.length > 0

    return (
        <StyledFeatureDataBar css={css}>
            <TaskHeader
                statusName={statusName}
                allStatusNames={allStatusNames}
                onStatusChanged={onStatusChanged}
                isOverdue={dueDate < Date.now()}
                isArchived={isArchived}
                hasNotesPending={hasNotesPending}
                isFollowing={isFollowing}
                onFollowClick={onFollowClick}
                participantsForFollowersSelector={participantsForFollowersSelector}
                followers={collaborationShape.followers}
                onFollowerSelected={onFollowerSelected}
            />
            <FlexColumn css={{ gap: 6 }}>
                {collaborationIdentifierComponent}
                <StyledNameInput
                    ref={inputRef}
                    data-cy="collaboration-name-input"
                    placeholder="Add task pin name..."
                    externalValue={name}
                    maxLineCount={4}
                    onBlur={onNameChanged}
                />
            </FlexColumn>

            <StyledDescriptionInput
                data-cy="collaboration-description-input"
                placeholder="Add task pin description..."
                externalValue={description || ''}
                maxLineCount={8}
                onBlur={onDescriptionChanged}
            />

            <Tags tags={tags} onTagsChanged={onTagsChanged} />
            <AssigneeAndDueDateButtons
                assignee={assignee}
                projectParticipantShapes={projectParticipantShapes}
                isArchived={isArchived}
                isCompleted={isCompleted}
                dueDate={dueDate}
                onDueDateChanged={onDueDateChanged}
                onAssigneeChanged={onAssigneeChanged}
            />
        </StyledFeatureDataBar>
    )
}

CollaborationWindowEditTask.propTypes = {
    name: PropTypes.string,
    description: PropTypes.string,

    commentShapes: PropTypes.arrayOf(PropTypes.object),
    tags: PropTypes.arrayOf(PropTypes.string), // tag ids

    isArchived: PropTypes.bool,
    isCompleted: PropTypes.bool,

    statusName: PropTypes.object.isRequired,
    allStatusNames: PropTypes.object.isRequired,
    assignee: PropTypes.object,
    projectParticipantShapes: PropTypes.arrayOf(PropTypes.object).isRequired,
    dueDate: PropTypes.object,

    onAssigneeChanged: PropTypes.func.isRequired,
    onStatusChanged: PropTypes.func.isRequired,
    onNameChanged: PropTypes.func.isRequired,
    onDescriptionChanged: PropTypes.func.isRequired,
    onTagsChanged: PropTypes.func.isRequired,
    onDueDateChanged: PropTypes.func.isRequired,
}

// for Storybook
export { TaskHeader, AssigneeAndDueDateButtons }

export default CollaborationWindowEditTask
