import { Organization } from '@range.io/basic-types'
import { assoc, taggedSum } from '@range.io/functional'
import { ReduxActions, ReduxSelectors } from '../../redux/index.js'
import CommandPlayer from './command-player.js'
import { createOrganization } from './https-calls.js'

// ---------------------------------------------------------------------------------------------------------------------
// ActionCreateOrganizationAddedCommand
// ---------------------------------------------------------------------------------------------------------------------
const OrganizationAddedCommand = taggedSum('OrganizationAddedCommand', {
    Inbound: { organization: 'Organization' },
    Outbound: {
        name: 'String',
        industry: 'String',
        organizationSize: 'String',
    },
})

// ---------------------------------------------------------------------------------------------------------------------
// Handle commands
// ---------------------------------------------------------------------------------------------------------------------

/*
 * An OrganizationAddedCommand.Inbound has arrived from Firestore; send it to redux
 * Make sure to keep the old participants; the new organization won't have any, since participants is a subcollection
 */
const runInboundCommand = (resources, command) => {
    const { dispatch, getState } = resources
    let { organization } = command

    // need to collect the participants from the existing Organization (if the was one)
    const oldOrganization = ReduxSelectors.organizationLookupTable(getState())[organization.id]
    const participants = oldOrganization?.participants || {}
    organization = Organization.from(assoc('participants', participants, organization))

    dispatch(ReduxActions.organizationAdded(organization))
}

/*
 * A ActionCreateOrganizationAddedCommand.Outbound has arrived from the UI; send it to redux AND Firestore
 */
const runOutboundCommand = async (resources, command) => {
    const { dispatch, displayError } = resources
    const { name, industry, organizationSize } = command

    try {
        const id = await createOrganization(name, industry, organizationSize)
        const organization = Organization.from({ id, name, industry, organizationSize, plan: 'Basic', projectIds: [] })
        dispatch(ReduxActions.organizationAdded(organization))
        dispatch(ReduxActions.selectedUserCreatedOrganization(organization))
        return id
    } catch (e) {
        displayError(e)
    }
}

const addCommand = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        OrganizationAddedCommand,
        CommandPlayer({
            CommandType: OrganizationAddedCommand,
            Type: Organization,
            documentPath: organizationId => `/organizations/${organizationId}/`,
            runInboundCommand,
            runOutboundCommand,
            addCommandToHistory,
            changeType: 'added',
            resourceKey: 'organizationId',
        })
    )
}

export { OrganizationAddedCommand, addCommand }
