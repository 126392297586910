import * as FirebaseApp from 'firebase/app'
import * as FirebaseAppCheck from 'firebase/app-check'
import * as FirebaseAuth from 'firebase/auth'
import * as FirebaseFirestore from 'firebase/firestore'
import * as FirebaseFunctions from 'firebase/functions'
import { getRemoteConfig } from 'firebase/remote-config'
import * as FirebaseStorage from 'firebase/storage'
import initializeSentry from './initialize-sentry.js'

/*
 * Return configuration object for Firebase
 * @sig getFirebaseConfiguration :: () -> Object
 */
const getFirebase = () => {
    const { hostname } = new URL(window.location.href)

    const hostnameMismatch = !hostname.match(/staging-range-io.web.app|staging.range.io/)
    if (typeof window === 'undefined' || hostnameMismatch) throw new Error(`Can't start`)

    return FirebaseApp.initializeApp({
        apiKey: 'AIzaSyDLm8T7Hh0wgbtAIvH3DKs0cf4fFDS2loo',
        appId: '1:442450513996:web:33c6d71303ac411e6fa4e9',
        authDomain: 'staging.range.io',
        projectId: 'staging-range-io',
        storageBucket: 'staging-range-io.appspot.com',
        messagingSenderId: '442450513996',
        measurementId: 'G-H2BTM5K952',
    })
}

// Initialize Firebase
const app = getFirebase()
const auth = FirebaseAuth.getAuth(app)
const storage = FirebaseStorage.getStorage(app)
const firestore = FirebaseFirestore.getFirestore(app)
const remoteConfig = getRemoteConfig(app)
const functions = FirebaseFunctions.getFunctions(app, 'us-west2')

initializeSentry('staging')

// no analytics for staging
const analytics = null

const appCheck = FirebaseAppCheck.initializeAppCheck(app, {
    provider: new FirebaseAppCheck.ReCaptchaV3Provider('6Lf3sS0kAAAAAPajICfIAQ1wmzZeDI_Gnilceve9'),
    isTokenAutoRefreshEnabled: true,
})

const KNOCK_PUBLIC_API_KEY = 'pk_VJ0aL1bkvqi9_aUPTfRtJE1HkqNzBKB2lVTJ8RXIBRU'

export { app, auth, firestore, storage, analytics, appCheck, remoteConfig, functions, KNOCK_PUBLIC_API_KEY }
