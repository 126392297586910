import { User } from '@range.io/basic-types'
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import { useKeyMaps } from '../components-reusable/hooks/index.js'
import { Box, Flex, FlexColumn, LoadingDotsAnimation, ScrollArea, Text } from '../components-reusable/index.js'
import { styled } from '../range-theme/index.js'
import CollaborationWindowEditPhoto from './CollaborationWindowEditPhoto.js'
import CollaborationWindowEditTask from './CollaborationWindowEditTask.js'
import CollaborationWindowFeedSection from './CollaborationWindowFeedSection.js'
import CollaborationWindowFileDropzone from './CollaborationWindowFileDropzone.js'
import CollaborationWindowTimelineToggle from './CollaborationWindowTimelineToggle.js'
import CollaborationWindowTopBar from './CollaborationWindowTopBar.js'
import CommentBox from './CommentBox.js'

const StyledOuterBox = styled(Box, {
    backgroundColor: '$neutral09',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '6px',
    border: '1px solid $neutral07',
    width: '100%',
    zIndex: 1,
})

const StyledHorizontalRule = styled(Box, {
    borderBottom: '1px solid $neutral07',
})

// Styled dashed vertical line
const StyledDashedLine = styled(Flex, {
    left: 40,
    border: '0.5px dashed $neutral06',
    position: 'absolute',
    boxSizing: 'border-box',
    top: 0,
    bottom: 0,
    flex: '1 1 auto',
    overflow: 'auto',
    zIndex: 0,
})

// ---------------------------------------------------------------------------------------------------------------------
// CollaborationWindowDumb
// ---------------------------------------------------------------------------------------------------------------------
const CollaborationWindowDumb = ({
    collaborationIdentifierComponent,
    collaborationShape,
    projectParticipantShapes,
    allowDelete = false,
    allowDuplicate = false,
    allowArchive = false,
    nameInputRef,

    allStatusNames,
    archiveText,
    assignee,
    canvases,
    currentCanvasId,
    dueDate,
    isArchived,
    showTimelineDetails,
    statusName,
    tags,
    user,
    scrollFeedToBottom,
    maxFileSize,
    dropzoneRootProps,
    dropzoneInputProps,
    isDragActive,
    isLoading,
    isFollowing,
    participantsForFollowersSelector,

    // functions
    onArchive,
    onAssigneeChanged,
    onAttach,
    onClose,
    onDelete,
    onDescriptionChanged,
    onDueDateChanged,
    onDuplicate,
    onNameChanged,
    onSaveComment,
    onShare,
    onShowTimelineDetailsChanged,
    onStatusChanged,
    onTagsChanged,
    onConvertToTask,
    onGoToCanvas = null, // if null then we won't show the button too
    onMoveToCanvas,
    onTakeCanvasSnippet,
    onFollowClick,
    onFollowerSelected,
}) => {
    // close on Escape key down
    useEffect(() => {
        pushKeyMap('CollaborationWindowDumb', { Escape: onClose })
        return () => popKeyMap('CollaborationWindowDumb')
    }, [])

    const pendingNotes = collaborationShape.comments.filter(c => c.isNote && !c.completedBy)
    const hasNotesPending = pendingNotes.length > 0
    const count = collaborationShape.uploads.length

    const scrollRef = useRef(null)
    const { pushKeyMap, popKeyMap } = useKeyMaps()
    const editPhotoProps = {
        collaborationIdentifierComponent,
        inputRef: nameInputRef,
        collaborationShape,
        name: collaborationShape?.name,
        onNameChanged,
        isArchived,
        hasNotesPending,
        count,
        tags,
        isFollowing,
        participantsForFollowersSelector,

        onTagsChanged,
        onFollowClick,
        onFollowerSelected,
    }
    const editTaskProps = {
        collaborationIdentifierComponent,
        inputRef: nameInputRef,
        collaborationShape,
        name: collaborationShape?.name,
        description: collaborationShape?.description,
        isArchived,
        isCompleted: statusName?.isCompleted,
        assignee,
        projectParticipantShapes,
        statusName,
        allStatusNames,
        tags,
        dueDate,
        isFollowing,
        participantsForFollowersSelector,

        onAssigneeChanged,
        onStatusChanged,
        onNameChanged,
        onDescriptionChanged,
        onTagsChanged,
        onDueDateChanged,
        onFollowClick,
        onFollowerSelected,
    }
    const feedProps = {
        user,
        showTimelineDetails,
        collaborationShape,
        statusNames: allStatusNames,
    }

    const topBarProps = {
        allowDelete,
        allowDuplicate,
        allowArchive,
        archiveText,
        canvases,
        currentCanvasId,
        onAttach,
        onShare,
        onDelete,
        onDuplicate: () => onDuplicate(collaborationShape),
        onArchive,
        onClose,
        onConvertToTask,
        onGoToCanvas,
        onMoveToCanvas,
        showGoToCanvas: onGoToCanvas !== null,
        onTakeCanvasSnippet,
    }
    const isTask = collaborationShape.isTask

    // after rendering, we may need to scroll to the bottom
    useEffect(() => {
        if (scrollFeedToBottom) scrollRef.current.scrollTop = scrollRef.current.scrollHeight
    })

    return (
        <StyledOuterBox data-cy="collaboration-window" key={collaborationShape.id}>
            {isLoading ? (
                <FlexColumn style={{ margin: 'auto' }}>
                    <LoadingDotsAnimation />
                    <Text css={{ color: '$neutral05', marginTop: 24 }}>Duplicating Pin</Text>
                </FlexColumn>
            ) : (
                <>
                    <CollaborationWindowTopBar {...topBarProps} />
                    <Box
                        {...dropzoneRootProps}
                        css={{
                            display: 'flex',
                            height: '100%',
                            overflow: 'hidden',
                            backgroundColor: isDragActive ? '$neutral10' : 'var(--colors-neutral09)',
                        }}
                    >
                        {isDragActive ? (
                            <CollaborationWindowFileDropzone maxFileSize={maxFileSize} />
                        ) : (
                            <ScrollArea ref={scrollRef}>
                                {isTask ? (
                                    <CollaborationWindowEditTask {...editTaskProps} />
                                ) : (
                                    <CollaborationWindowEditPhoto {...editPhotoProps} />
                                )}
                                <StyledHorizontalRule />
                                <CollaborationWindowTimelineToggle
                                    checked={showTimelineDetails}
                                    onCheckedChange={onShowTimelineDetailsChanged}
                                />
                                <CollaborationWindowFeedSection {...feedProps} />
                                <StyledDashedLine />
                            </ScrollArea>
                        )}
                    </Box>
                    <CommentBox
                        allowNote={true}
                        allowFollowersSelector={true}
                        onFocusChange={isFocused => {}}
                        onCommentSubmit={onSaveComment}
                        userName={User.fullName(user)}
                        userAvatarUrl={user.avatarUrl}
                        css={{ mt: 'auto' }}
                        participantsForFollowersSelector={participantsForFollowersSelector}
                        onFollowerSelected={onFollowerSelected}
                        followers={collaborationShape.followers}
                    />
                </>
            )}
        </StyledOuterBox>
    )
}

CollaborationWindowDumb.propTypes = {
    collaborationShape: PropTypes.object.isRequired,

    user: PropTypes.object.isRequired, // User
    assignee: PropTypes.object, // User
    statusName: PropTypes.object,
    tags: PropTypes.arrayOf(PropTypes.string),
    dueDate: PropTypes.object,

    archiveText: PropTypes.string.isRequired,
    currentCanvasId: PropTypes.string.isRequired,

    onNameChanged: PropTypes.func.isRequired,
    onDescriptionChanged: PropTypes.func.isRequired,
    onTagsChanged: PropTypes.func.isRequired,
    onStatusChanged: PropTypes.func,
    onAttach: PropTypes.func.isRequired,
    onShare: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onDuplicate: PropTypes.func.isRequired,
    onArchive: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onSaveComment: PropTypes.func.isRequired,
    onDueDateChanged: PropTypes.func.isRequired,
    onTakeCanvasSnippet: PropTypes.func.isRequired,
}

export default CollaborationWindowDumb
