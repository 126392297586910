import PropTypes from 'prop-types'
import React from 'react'
import { Flex, FlexRow, RowBox, FlexColumn } from '../components-reusable/index.js'
import { styled } from '../range-theme/index.js'
import CollaborationWindowBadge from './CollaborationWindowBadge.js'
import { StyledFeatureDataBar, StyledNameInput } from './CollaborationWindowCommon.js'
import Tags from './Tags.js'
import { FollowButtonSimple } from './FollowButton.js'
import FollowersSelector from './FollowersSelector.js'

const StyledArchivedBubble = styled(Flex, {
    flexDirection: 'row',
    alignItems: 'center',
    fontFamily: '$default',
    fontWeight: 500,
    fontSize: 14,
    padding: '7px 10px',
    borderRadius: 999,
    color: 'white',
    backgroundColor: '$archived',
    height: 32,
})
/*
 * Edit the name of a Photo Collaboration
 */
const CollaborationWindowEditPhoto = props => {
    const {
        collaborationIdentifierComponent,
        name = '',
        onNameChanged,
        tags,
        onTagsChanged,
        count = 0,
        isArchived = false,
        hasNotesPending = false,
        inputRef,
        isFollowing,
        collaborationShape,
        participantsForFollowersSelector,
        onFollowClick,
        onFollowerSelected,
    } = props

    return (
        <StyledFeatureDataBar variant="photo">
            <RowBox css={{ gap: 10, flexDirection: 'row', justifyContent: 'space-between' }}>
                <FlexRow
                    style={{
                        gap: '10px',
                        flexDirection: 'row',
                    }}
                >
                    <CollaborationWindowBadge isArchived={isArchived} hasNotesPending={hasNotesPending} count={count} />
                    {isArchived && <StyledArchivedBubble>Archived</StyledArchivedBubble>}
                </FlexRow>
                <FlexRow>
                    <FollowButtonSimple isFollowing={isFollowing} onClick={() => onFollowClick(!isFollowing)} />
                    <FollowersSelector
                        multiSelect
                        participants={participantsForFollowersSelector}
                        onFollowerSelected={id => {
                            onFollowerSelected(id)
                            console.log('follower clicked')
                        }}
                        followers={collaborationShape.followers}
                    />
                </FlexRow>
            </RowBox>
            <FlexColumn css={{ gap: 6 }}>
                {collaborationIdentifierComponent}
                <StyledNameInput
                    ref={inputRef}
                    data-cy="collaboration-name-input"
                    placeholder="Add photo pin name..."
                    externalValue={name}
                    maxLineCount={4}
                    onBlur={onNameChanged}
                />
            </FlexColumn>
            <Tags tags={tags} onTagsChanged={onTagsChanged} />
        </StyledFeatureDataBar>
    )
}

CollaborationWindowEditPhoto.propTypes = {
    name: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string).isRequired, // tag ids
    count: PropTypes.number,
    isArchived: PropTypes.bool,
    hasNotesPending: PropTypes.bool,

    onNameChanged: PropTypes.func.isRequired,
    onTagsChanged: PropTypes.func.isRequired,
}

export default CollaborationWindowEditPhoto
